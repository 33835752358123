import * as React from "react";

// markup
const NotFoundPage = () => {
   return (
      <article>
         <h1>Page not found</h1>
      </article>
   );
};

export default NotFoundPage;
